import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Highlight from "react-highlight.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`CZaas V2`}</h1>
    <h2>{`On the backend`}</h2>
    <p>{`I decided to go with Express JS for the backend because it's a solid framework with easy setup for routing and customization for middleware and I have the most experience with it.`}</p>
    <p>{`If we take a look at the meat of the server:`}</p>
    <p><strong parentName="p">{`Static assets`}</strong>{` - Images, CSS, JS`}</p>
    <Highlight language="javascript" mdxType="Highlight">
  {`app.use(express.static('./server/public'));`}
    </Highlight>
    <p><strong parentName="p">{`API`}</strong>{` - A single API route that looks for requests before sending the single HTML file. More on the endpoint later.`}</p>
    <Highlight language="javascript" mdxType="Highlight">
  {`var v1PagesHandler = require('./apiv1/pages-handler.js');
app.use('/api/v1/pages', v1PagesHandler);`}
    </Highlight>
    <p><strong parentName="p">{`Catch all`}</strong>{` - Anything that doesn't exists in the public folder or does not match the API routes.`}</p>
    <p>{`This is the only HTML file served for the entire site regardless of which URL path is requested.`}</p>
    <Highlight language="javascript" mdxType="Highlight">
  {`app.get('*', function(req, res) {
    res.sendFile(__dirname + '/public/index.html');
});`}
    </Highlight>
    <h3>{`The API endpoint`}</h3>
    <p>{`I decided I needed a server that would have an endpoint I could pass a route to, and return just the markdown content along with the meta for the page requested.`}</p>
    <p>{`I have a folder with just markdown files where this is setup to look for the files. At the time of writing this, I have 3 functions which are setup to look for markdown files and 1 function which converts the found markdown file content into a JSON object to send back to the front end.`}</p>
    <ol>
      <li parentName="ol">{`Has a quick sanity check to make sure the first character requested is a forward slash. If not, it sends down the 404 content.`}</li>
      <li parentName="ol">{`Then it runs the `}<inlineCode parentName="li">{`lookForFolderIndex`}</inlineCode>{` function which looks for a folder with the requested path and checks if an index.md file exists, if so, sends it back.`}</li>
      <li parentName="ol">{`Within the step 2 function, if it fails, it will run `}<inlineCode parentName="li">{`lookForFile`}</inlineCode>{` function which looks for a file with the name of the path requested then sends it back.`}</li>
      <li parentName="ol">{`If both step 2 and 3 fails, it runs the final function `}<inlineCode parentName="li">{`getFourZeroFour`}</inlineCode>{` which gets the 404 file and sends it down.`}</li>
    </ol>
    <Highlight language="javascript" mdxType="Highlight">{`if (req.query.path[0] === '/') {
  lookForFolderIndex(req.query.path, function(fileRes) {
    res.send(processMarkdown(fileRes));
  });
} else {
  getFourZeroFour(function(content) {
    res.send(processMarkdown(content));
  });
}
`}</Highlight>
    <h2>{`On the front end`}</h2>
    <p>{`I've been on a kick lately with the `}<a href="https://github.com/hyperapp/hyperapp" target="_blank">{`Hyperapp SPA framework`}</a>{`. Its selling point is it's a 1kb library with a virtual DOM and state management included.`}</p>
    <p>{`On page load once the JS is parsed this app collects the current window path and makes a fetch request to the API. It will receive a JSON object back looking something like this:`}</p>
    <Highlight language="json" mdxType="Highlight">
  {`{
  content: {
    meta: {
      title: "...",
      description: "...",
    },
    html: "...."
  },
  success: true
}`}
    </Highlight>
    <p>{`Once that object is received, it updates the meta and page content. The Markdown to HTML parsing is happening on the server. This application tracks the loading indicator and whether the mobile menu is open.`}</p>
    <hr></hr>
    <p><em parentName="p">{`Note: This is an older version of my site. Please `}<a href="https://github.com/czaas/czaas-v2" target="_blank">{`view the repository`}</a>{` to see how the code works. Post coming soon about the site you are viewing this on.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      